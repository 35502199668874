.carousel {
    width: 50%;
    margin-bottom: 10%;
}

img {
    object-fit: cover;
}


.caro-img {
    width: 48%;
    object-fit: contain;

}

.caro-box {
    margin-bottom: 55px;
}


img:hover {
    cursor: pointer;
}

.caro-text {
    background-color: rgb(41, 41, 41);
    color: whitesmoke;
    text-shadow: 1px 1px 1px black;
    text-align: center;
    padding: 15px;
    margin-top: 0;
}

.caro-title {
    background-color: rgb(41, 41, 41);
    color: whitesmoke;
    text-shadow: 1px 1px 1px black;
    margin-bottom: 0;
    text-align: center;
}

button {
    border-radius: 50%;
    margin: 5px;
    border: 2px solid black;
    height: 1em;
}

.carousel-control-prev {
    display: none;
}

.carousel-control-next {
    display: none;
}