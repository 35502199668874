#big {
    display: flex;
    flex-direction: column;
    align-items: center;

}

h1 {
    color: whitesmoke;
    font-size: 52px;
    font-weight: bold;
    text-align: center;
    margin: 15px;
}

#fade-box {
    position: absolute;
    width: 760px;
    height: 400px;
    background-color: black;
}

#fade-box-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

#ralof:hover {
    cursor: default;
}

#backhome {
        font-size: 52px;
        text-align: center;
        margin: 15px;
}

img {
    height: 25em;
}

/* Most Mobile */
@media all and (max-width: 699px) {
    img {
        height: 10em;
    }

    #fade-box {
        position: absolute;
        height: 10em;
        background-color: black;
    }
}