.card-img-top {
    height: 15em;
    object-fit: cover;

}

.card {
    margin: 10px;
}

#cv-title {
    margin: 10px;
}

#Iam {
    text-shadow: none;
    margin: 50px;
    word-wrap: none;
}

#card-box {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}