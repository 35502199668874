#big-container {
    width: 100vw;
    background-color: #111013;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ka-logo {
    background-color: white;
    border-radius: 50%;
    padding: 15px;
    opacity: 0;
    margin-top: 20px;
    transform: translateX(4000px);
}

.box-top {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    padding-top: 15px;
    margin: 16px;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    border-radius: 10px;
    color: black;
}

.box-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.slide-3-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cert-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px;
    justify-content: space-between;
    align-items: center;
}

.cert {
    height: 35em;
}

.cert-text-flex {
    display: flex;
    flex-direction: column;
}

.box-top>h2 {
    margin-bottom: 15px;
    text-decoration: underline;
}

.box-top>a>img {
    max-width: 100%;
    max-height: 100%;
}

.box-text {
    text-decoration: none !important;
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding: 25px;
    width: 100%;
}

.box-top>a {
    text-decoration-color: #007bff;
    color: #005dc1;
    font-weight: bold;
}

.box-thumb {
    padding-bottom: 15px;
    height: 25em;
}

.box-thumb:hover {
    cursor: pointer;
    filter:brightness(50%);
    transition: 0.2s ease all;
}

.box-top>h2 {
    color: black;
}


#img-cover-div {
    position: absolute;
    background-color: #111013;
    width: 4000px;
    height: 400px;
}

#img-cover-div-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#ka-text {
    line-break: break-word;
    width: 25%;
    font-size: 1.5em;
}

#ka-logo:hover {
    cursor: pointer;
}

#ka-pic-and-text-flex {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.education-flex {
    font-size: 45px
}

.education-text {
    margin-top: 20%;
    margin-bottom: 10%;
}

/* Most Mobile */
@media all and (max-width: 699px) {

    #ka-text {
        font-size: 16px;
        width: 100%;
    }

    #img-cover-div {
        height: 450px;
    }


    #ka-pic-and-text-flex {
        flex-direction: column;
    }
}