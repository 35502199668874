.hvac-photo{ 
    transform: translateX(1000px);
    opacity: 0;
}

.hvac-p { 
    display: flex; 
    align-items: center;
    justify-content: center;
    margin-left: 35px;
    transform: translateX(-1000px);
    opacity: 0;   
}

.pr-flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 35px;
    flex-direction: row;
}

.pr-photo {
    width: 20em;
    object-fit: contain;
}

.experience-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    margin: 25px;
    font-size: 35px;
}


.fly-left {
    transform: translateX(-2000px);
}

.fly-left-2 {
    transform: translateX(-2000px);
}

.fly-right {
    transform: translateX(2000px);
}

.pr-photo {
    transform: translateX(-2000px);
}

.pr-text {
    transform: translateX(2000px);
}

.pr-text {
    padding: 10px;
}

.fold-down-contr {
    font-size: 3em;
}

.fold-down-contr,
.fold-down-contr-2,
.fold-down-contr-3,
.fold-down-contr-4,
.fold-down-contr-5,
.fold-down-contr-6,
.fold-down-contr-7{
    transform: rotateX(-90deg);
    color: white;
    width: auto;
    border-radius: 10px;
    perspective: 1200px;
}

.hvac-description {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 35px;
    flex-direction: row;
}

.feature-img {
    height: 30%;
}

.features-flexbox {
    margin-top: 8em;    
    margin-bottom: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.experience-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.feature-info-text {
    font-size: 24px;
    width: 50%;
}

/* Most Mobile */
@media all and (max-width: 699px) {
    .hvac-p {
        margin-left: 0px;
        padding:15px;
    }
    
    h3 {
        font-size: 1.5em !important;
    }
    
    p {
        font-size: 16px !important;
    }
    
    .feature-img {
        width: 15em;
    }
    
    .pr-photo {
        width: 250px;
        margin: 5px;
        object-fit: cover;
    }
}


