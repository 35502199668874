.bug-container {
    height: 300vh;
    width: 100vw;
}

.bug-box {
    border: 5px solid salmon;
    height: 100vh;
    width: 98vw;
    scale: 0;
    overflow: hidden;
}

.bug {
    height: 50px;
    width: 50px;
    position: absolute
}

#splatter {
    height: 50px;
    width: 50px;
    color: salmon;
    -webkit-user-select: none;
    user-select: none;
    animation: none !important;
    transition: none !important;
    position: absolute;
}

.bug:hover {
    cursor: pointer;
}

#squash {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    z-index: -1;
    position: relative;
    height: 100%;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 4em;
    opacity: 0;

}