:root {
    --background-color: white;
    --border-color: white;
    --border-bottom-h2-color: red;
    --text-shadow-about: black;
    --h5-border-color: black;
    --card-container-border-color: black;
    --resume-img-color: black;
    --social-links-color: white;
    --social-links-border-color: black;
    --download-border-color: black;
    --download-text-color: black;
    --header-color: white;
    --desc-text-color: black;
    --dot-color: black;
    --border-logo: black;
    --input-border: black;
    --contact-box-gradient: linear-gradient(90deg, #c7c1c1 50%, #191a1b 50%);
    --box-shadow-hover-color: black;
    --card-p-color: whitesmoke;
    --text-shadow-card: black;
    --card-title-color: #1f232a;
    --abo-text: white;
    --caro-slide-img-color: black;
    --night-mode-toggle-color: #09aee9;
    --label-text-color: white;
    --email-p: black;
}

html,
body {
    max-width: 100%;
    height: auto;
    contain: paint;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
    background-color: #111013;
    background-position: cover;
}

#big {
    position: relative;
}

#big-p-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
}

#qualbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

hr {
    height: 1px;
    background-color: var(--background-color);
    width: 100%;
}

.divider {
    height: 100vh
}

#parallax {
    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: #111013;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: sticky;
    top: 0;
    background-position: center;
    -webkit-overflow-scrolling: touch;
    border-bottom: 10px var(--border-color);
}

#parallax2 {
    width: 100vw;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
}

#abo2 {
    margin-right: 200px;
    margin-top: 20px;
    border-bottom: var(--border-bottom-h2-color) solid 10px;
    color: var(--abo-text);
    width: auto;
    text-align: center;
    text-shadow: 1px 1px var(--text-shadow-about);
    border-spacing: 20px;
}

#parallax3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
}

#cv-text {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    line-height: 25px;
}

#pic-flex {
    display: flex;
    background-color: white;
    overflow-y: hidden;
    border-radius: 50%;
}

#me-pic {
    text-align: center;
    object-fit: cover;
    width: 25em;
    height: 25em;
    border-radius: 50%;
    transform: translateY(8%)
}

#me-pic:hover {
    cursor: default;
}


#profile-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 50vw;
    font-size: 18px;
    justify-content: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border: 2px solid black;
    color: rgb(173, 141, 141);
}

#night-mode-toggle-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
}

#night-mode-toggle {
    width: 5.5em;
    height: 2.5em;
    padding: 5px;
    background: var(--night-mode-toggle-color);
    border-radius: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px;
    transition: all 0.3s ease;
    border: 5px solid black;
}

#toggle-ticker {
    width: 22px;
    height: 22px;
    border: 1px solid white;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 50%;
}

#toggle-ticker:hover {
    cursor: pointer;
}

p,
h2,
h5 {
    color: var(--abo-text);
    font-weight: bold;
}

#nav-links-about-me {
    display: flex;
    flex-direction: row;
}

#desc-box {
    display: flex;
    flex-direction: row;
}

#cv-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: rgb(1, 42, 74);
    border: 2px solid black;
    border-top: 0;
}

#text-box {
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    overflow-wrap: break-word;
    line-height: 20px;
    width: 100%;
}

.card-text {
    text-align: center;
    padding: 5px;
    font-size: 14px;
}

.card-title {
    text-align: center;
}

h5 {
    border-bottom: 2px solid var(--h5-border-color);
}

.progress {
    margin: 15px;
    height: 2.5em;
}

#footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: var(--background-color);
    padding: 25px;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    align-items: center;
}

.footerlinks {
    margin-left: 50px;
}

#night-mode-text-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerp {
    margin-right: 50px;
    font-size: 15px;
    color: var(--abo-text)
}

#axe-logo {
    height: 3em;
}

#axe-link {
    text-decoration: underline;
    color: rgb(0, 0, 145)
}

#axe-link:hover {
    color: blue;
}

.bottom-text {
    color: black;
    text-align: center;
}

.box-card {
    padding: 10px;
    height: 20em;
    text-align: center;
    margin: 10px;
}

.carousel .slide img {
    border: 2px solid var(--caro-slide-img-color) !important;
}

.logo {
    object-fit: contain;
}

#skills-img {
    margin: 10px;
}

.card-container {
    margin: 10px;
    background-color: var(--background-color);
    border: 2px solid var(--card-container-border-color);
}

.card-container:hover {
    transition: all 0.3s ease;
    box-shadow: 5px 5px var(--card-container-border-color);
}

#cow {
    margin: 30px;
}

#figbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#cow:hover {
    transition: all 0.8s ease;
    box-shadow: 0 0 30px 15px #fff,
        /* inner white */
        0 0 50px 30px black,
        /* middle magenta */
        0 0 70px 40px black;
    /* outer cyan */
}

#caption {
    background-color: rgba(0, 0, 0, 0.6);
    border: 2px solid black;
    padding: 15px;
    text-align: center;
}

.black-h2 {
    color: black;
    text-shadow: none;
}

#work-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}


#bottom {
    margin: 20px;
    text-align: center;
    font-size: 20px;
}

#bottom:hover {
    transition: all 0.8 ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

#resume-img {
    object-fit: cover;
    width: 25em;
    border: 3px solid var(--resume-img-color);
    border-radius: 10%;
    margin: 5px;
}

.card-img {
    height: 10em;
    object-fit: cover;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.card-img:hover {
    cursor: pointer;
}

.card-text {
    color: black;
    font-size: 1em;
}

.title {
    font-size: 1.5em;
    border-bottom: 1.5px solid black;
    color: black;
    font-weight: bold;
}


#social-links {
    background-color: var(--social-links-color);
    border: 2px solid var(--social-links-border-color);
    border-radius: 20%;
}


body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

#Iam {
    margin: 10px;
    width: 50%;
    z-index: 2;
    position: relative;
    background: url("../resources/youfoundthekitten.png") -20px -20px no-repeat;
    color: transparent;
    padding-bottom: 5px;
    -webkit-text-stroke: 1px gray;
    background-position: -2000px 0px;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 3.5em;
    text-align: left;
}

#scroller-div {
    position: absolute;
    z-index: 1;
    background: url(https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F4.bp.blogspot.com%2F-W-Q7_l1OoxY%2FUWAJSrtvn_I%2FAAAAAAAASVM%2FeMqye-hpAu4%2Fs1600%2Fworld-s-cutest-kitten.jpg&f=1&nofb=1&ipt=2af7013c56d9f9417875db82f9371f6a59e05f248f3dd4d54ac0f049266dd4f5&ipo=images) -20px -20px no-repeat;
    left: -100vw;
    object-fit: cover;
    height: 400px;
    width: 400px;
}

#linksbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#linksbox>a {
    margin: 5px;
    border-radius: 50%;
    padding: 2px;
}

#abo {
    margin-right: 200px;
    margin-top: 20px;
    border-bottom: var(--border-bottom-h2-color) solid 10px;
    color: var(--abo-text);
    width: auto;
    text-shadow: 2px 2px 2px var(--text-shadow-about);
    border-spacing: 20px;
}

#resumeexp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    align-items: center;
}

#cert-imgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#certs {
    margin: 20px;
}

#cert-tiny {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

#download {
    border: 2px solid var(--download-border-color);
    text-decoration: none;
    padding: 15px;
    color: rgb(169, 214, 229);
    font-weight: bold;
    color: var(--download-text-color);
}

#desc-box-exp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#big-exp-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

#header {
    font-size: 35px;
    color: var(--header-color);
    font-weight: bold;
    text-shadow: none;
}

#header2 {
    font-size: 25px;
    color: var(--header-color);
    border-bottom: solid 10px var(--border-bottom-h2-color);
    text-shadow: none;
    text-shadow: 1px 1px var(--text-shadow-about);
}

#paul-desc {
    text-align: center;
    display: flex;
    text-align: center;
    inline-size: 400px;
    margin: 20px;
    color: var(--desc-text-color);
    text-shadow: none;
    overflow-wrap: break-word;
    justify-content: center;
    align-items: center;
}

#learnmore {
    margin: 20px;
}

.dot {
    background-color: var(--dot-color) !important;
}

.logo {
    margin: 15px;
    border: solid 2px var(--border-logo);
    border-radius: 50%;
    padding: 2px;
    width: 25px;
}

.radio-container {
    display: flex;
}

.radio-buttons {
    height: 10px;
    width: 10px;
    margin: 10px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}

#sticky-hamburger {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000000000;
    transition: 0.2s all ease;
    width: 100px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.518);
    border-radius: 500px;
}

#hamburger {
    width:50%;
    height: 30%;
    display: flex;
    transition: 0.2s ease all;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.hamburger-stick {
    width: 100%;
    border-radius: 5px;
    transition: 0.2s ease all;
    background-color: black;
    height: 5px
}

.hamburger-anchor {
    color: black;
    width: 100%;
    height: 100%;
}

.hamburger-anchor:hover {
    text-decoration: none;
    color: black;
}

#hamburger-menu {
    position: absolute;
    width: 200px;
    padding: 0;
    border-radius: 5px;
    transition: 0.3s ease all;
    top: 70px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.511);
}

.hamburger-label {
    border-bottom: 2px solid black;
    margin: 10px;
    color: black;
    border-radius: 5px;
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
    border-width: 100%;
}

.hamburger-label:hover {
    background-color: rgba(143, 143, 143, 0.568);
}

#hamburger-menu:hover {
    background-color: white;
}

#sticky-hamburger:hover {
    cursor: pointer;
    background-color: white;
}

.slide-1 {
    transform: translateX(0px);
}

.slide-2 {
    transform: translateX(2000px);
}

.slide-2, .slide-3 {
    height: 0px;
}

.slide-3 {
    transform: translateX(-2000px);
}

.slide-1,.slide-2,.slide-3 {
    transition: all 0.5s ease;
}

.radio-1 {
    filter: brightness(50%);
}


.radio-buttons:hover {
    cursor: pointer;
    filter: brightness(50%);
}

.logo:hover {
    cursor: pointer;
}

#condetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#contactformbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
}

#contactbox {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    width: 100%;
}

.volunteer-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25%;
    align-items: center;
}

.box-top {
    display: flex;
    flex-direction: column;
    background-color: rgb(212, 212, 212);
    padding-top: 15px;
    margin: 16px;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    border-radius: 10px;
    color: black;
}

.box-top>h2 {
    margin-bottom: 15px;
    text-align: center;
    text-decoration: underline;
}

.box-top>a>img {
    max-width: 100%;
    max-height: 100%;
}

.box-text {
    text-decoration: none !important;
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding: 25px;
    width: 100%;
}

.box-top>a {
    text-decoration-color: #001b38;
}

.box-thumb {
    padding-bottom: 15px;
    height: 25em;
}

.box-thumb:hover {
    cursor: pointer;
    filter:brightness(50%);
    transition: 0.2s ease all;
}

.box-top>h2 {
    color: black;
}

#form-warning-message {
    text-shadow: 1px 1px black;
    font-weight: bold;
    font-size: 1.5em
}

#red-text {
    color: red;
    font-weight: normal;
}

.submit {
    margin: 20px;
}

input.submit:hover {
    filter: brightness(50%);
    cursor: pointer; 
}



textarea,
input {
    border: 1px solid var(--input-border);
}

label {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: var(--label-text-color);
    text-shadow: 1px 1px var(--text-shadow-about);
}

#emailp {
    text-shadow: 1px 1px var(--text-shadow-about);
    color: (var(--email-p))
}

#contact-box {
    background: var(--contact-box-gradient);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

#download:hover {
    box-shadow: var(--box-shadow-hover-color) 2px 2px;
    transition: 0.5 ease all;
}

#linksbox>a:hover {
    box-shadow: var(--box-shadow-hover-color) 2px 2px;
    transition: 0.5 ease all;
}

.readme-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 5px;
    border: 1px solid #21262D;
    background-color: #0D1117;
}

.slides-container {
    margin-bottom: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.readme-contents {
    padding: 15px;
}

.readme-contents>h2,
.readme-contents>h1 {
    border-bottom: 1px solid #21262D;
    padding-bottom: 15px;
}

p>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#axe-logo {
    display: inline;
}

.readme-top-box>p {
    display: inline;
}

.readme-top-box>p>a {
    color: white;
    text-decoration: none;
}

.readme-top-box>p>a:hover {
    color: #0044cc;
    text-decoration: underline;
}

.readme-logo {
    width: 2em;
    display: inline;
    height: 2em;
}

.readme-top-box {
    border: 1px solid #21262D;
    border-left: none;
    padding: 15px;
    border-right: none;
    border-top: none;
    width: 100%;
}

li {
    color: whitesmoke;
}

@media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) and (pointer:coarse) {

    /* CSS rules specific to Brave browser on iOS */
    #parallax {
        position: fixed;
    }
}


/* Most Mobile */
@media all and (max-width: 699px) {

    /* #big {
        display: flex;
        flex-direction: column;
    } */
    #work-box {
        display: flex;
        flex-direction: column;
    }
    
    
#sticky-hamburger {
    width: 80px;
    height: 80px;
}

.box-flex {
    flex-direction: column;
}

.cert {
    width: 85%;
    object-fit: contain;  
}

.volunteer-header {
    text-align: center;
}

.volunteer-box-top {
    width: 70% !important ;
}

#open-source-box-top {
    width: 70% !important ;
}

.box-top {
    width: 70% !important;
}

.cert-text {
    font-size: 16px;
}

.cert-flex {
    flex-direction: column !important;
}

.readme-contents>h1 {
    font-size: 25px;
}

.readme-contents>p {
    font-size: 12px !important;
}

#hamburger-menu {
    width: 150px;
}

.hvac-p {
    width: 25%;
}


.hvac-photo {
    width: 10em;
}

.hamburger-label {
    font-size: 16px;
}

    #resume-img {
        object-fit: contain;
    }

    .submit {
        margin: 20px;
    }

    .logo {
        margin: 2px;
    }

    #big-exp-box {
        flex-direction: column;
    }

    #certs {
        width: 200px;
    }

    #abo {
        margin-right: 50px;
        font-size: 20px;
    }

    .footer-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footerlinks {
        margin-left: 0;
    }

    #form {
        width: 50%;
    }

    .footerDownload {
        font-size: 12px;
    }

    #download {
        padding: 10px;
    }

    #parallax {
        height: 100vh;
        flex-direction: column;
    }

    #cert-imgs {
        flex-direction: column;
    }

    #certs {
        width: 100%;
    }

    #desc-box {
        flex-direction: column;
    }

    #paul-desc {
        inline-size: 180px;
    }

    #Iam {
        font-size: 18px;
    }

    #skills-img {
        width: 100vw;
        object-fit: contain;
    }

    #header {
        font-size: 18px;
    }

    #header2 {
        font-size: 16px;
    }

    #paul-desc {
        font-size: 12px;
    }

    #abo {
        font-size: 18px;
    }

    #big-p-box {
        width: 75vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        font-stretch: condensed;
    }

    #cv-box {
        width: 85vw;
    }

    #me-pic {
        width: 25vw;
        height: 25vw;
        object-fit: cover;
    }

    .readme-box {
        width: 75%;
    }

    #profile-box {
        width: 100vw;
        flex-direction: column;
        padding: 50px;
    }

    #figbox {
        width: 100vw;
    }

    .resume-box {
        width: 100vw;
        align-self: center;
    }

    #big-exp-box {
        align-items: center;
    }
}

.symbols {
    height: 2em;
    margin-left: 8px;
    margin-bottom: 5px;
    object-fit: cover;
}

.card-p {
    font-size: 1.5em;
    color: var(--card-p-color);
    text-shadow: 2px 2px var(--text-shadow-card);
}

.card-title {
    display: flex;
    background-color: var(--card-title-color);
    flex-direction: row;
    height: 4em;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}




/* Ipads */
@media all and (max-width: 1000px) {
    #Iam {
        font-size: 25px;
        width: 80%;
    }


    #big-exp-box {
        flex-direction: column;
    }


    #me-pic {
        width: 25vw;
        height: 25vw;
        object-fit: cover;
    }
}